import work1 from "../Assets/post9.png"
import work2 from "../Assets/termokos5.jpg"
import work3 from "../Assets/mokne.png"
import work5 from "../Assets/termokos.png"

import vegza1 from "../Assets/post10.png"
import vegza2 from "../Assets/post3.png"
import vegza3 from "../Assets/post5.png";
import vegza4 from "../Assets/vegza.mp4"
import vegza5 from "../Assets/post7.jpg"
import dok from "../Assets/DokuTechFinal.mp4"

import termokosBanner from "../Assets/firstTermokos.jpg"
import termokos2 from "../Assets/termokos1.jpg"
import termokos3 from "../Assets/termokos2.jpg"
import termokos4 from "../Assets/termokos3.jpg"
import termokos5 from "../Assets/termokos5.jpg"
import termokos6 from "../Assets/termokosVideo.mp4"
import termokos7 from "../Assets/Logo_Animation.mp4"

import mokne1 from "../Assets/mokne1.png"
import mokne2 from "../Assets/mokne2.png"
import mokne3 from "../Assets/mokneVideo1.mp4"
import mokne4 from "../Assets/mokne4.png"
import mokne5 from "../Assets/mokne5.png"
import mokne6 from "../Assets/mokneVideo2.mp4"

import mainBoyut from "../Assets/boyut1.png"
import boyut2 from "../Assets/BOYUT2.png"
import boyut3 from "../Assets/boyut3.png"
import boyut4 from "../Assets/boyut4.png"
import boyut5 from "../Assets/boyut5.png"
import boyut6 from "../Assets/boyut6.png"
import boyut7 from "../Assets/boyut7.png"
import boyut8 from "../Assets/boyut10.mp4"
import boyut9 from "../Assets/boyut8.png"
import boyut10 from "../Assets/boyut9.png"
import boyut11 from "../Assets/boyutAnimation.mp4"
import boyut12 from "../Assets/boyut11.mp4"
import boyut13 from "../Assets/boyut12.png"
import boyut14 from "../Assets/boyut13.png"
import boyut15 from "../Assets/boyut14.png"
import boyut16 from "../Assets/boyut15.mp4"
import boyut17 from "../Assets/boyut16.png"

import frutaria from "../Assets/frutaria1.png"
import frutaria1 from "../Assets/frutaria2.png"
import frutaria2 from "../Assets/frutaria3.png"
import frutaria3 from "../Assets/frutaria4.png"
import frutaria4 from "../Assets/frutaria5.png"
import frutaria5 from "../Assets/frutaria6.png"
import frutaria6 from "../Assets/frutaria7.png"
import frutaria7 from "../Assets/Dardha_pike (1).mp4"

import drops from "../Assets/drops1.png"
import drops1 from "../Assets/drops2.png"
import drops2 from "../Assets/drops3.png"
import drops3 from "../Assets/drops4.png"
import drops4 from "../Assets/drops5.png"
import drops5 from "../Assets/drops6.png"
import drops6 from "../Assets/drops7.png"
import drops7 from "../Assets/drops-mp4.mp4"
import drops8 from "../Assets/drops-wine (1).mp4"

import binni1 from "../Assets/binni2.png"
import binni2 from "../Assets/binni1.png"
import binni3 from "../Assets/binni3.png"
import binni4 from "../Assets/binni4.png"
import binni5 from "../Assets/binni5.png"
import binni6 from "../Assets/binni6.png"
import binni7 from "../Assets/binni7.png"

import dokutech1 from "../Assets/X_post_.mp4"
import dokutech2 from "../Assets/teaser_X_2.mp4"
import dokutech3 from "../Assets/D_post (1).mp4"
import dokutech4 from "../Assets/O.mp4"
import dokutech5 from "../Assets/K (1).mp4"
import dokutech6 from "../Assets/U.mp4"
import dokutech7 from "../Assets/T.mp4"
import dokutech8 from "../Assets/E.mp4"
import dokutech9 from "../Assets/C_POST.mp4"
import dokutech10 from "../Assets/H_Post.mp4"

import dokutech2022 from "../Assets/GMp2vBBp7pFVVlUBAOFv2ClIEq9gbkYLAAAF (1).mp4"
import dokutech20221 from "../Assets/GL-MRxH09q4g6ZgBAE_R8kMKyiQ6bkYLAAAF.mp4"
import dokutech20222 from "../Assets/GJ2C0BAh4WDaE3MCAA7f7Q00nmUnbvVBAAAF (1).mp4"
import dokutech20223 from "../Assets/dokutech2022-1 (1).jpg"
import dokutech20224 from "../Assets/dokutech2022-1 (2).jpg"
import dokutech20225 from "../Assets/dokutech2022-1 (3).jpg"
import dokutech20226 from "../Assets/dokutech2022-1 (4).jpg"
import dokutech20227 from "../Assets/dokutech2022-1 (5).jpg"
import dokutech20228 from "../Assets/dokutech2022-1 (6).jpg"
import dokutech20229 from "../Assets/dokutech2022-1 (7).jpg"
import dokutech202210 from "../Assets/dokutech2022-1 (8).jpg"
import dokutech202211 from "../Assets/dokutech2022-1 (9).jpg"
import dokutech202212 from "../Assets/dokutech2022-1 (10).jpg"

import endritiPremium from "../Assets/Jaguar_Endriti_Pre-Final (1).mp4"
import endriti1 from "../Assets/endriti1.png"
import endriti2 from "../Assets/ENDRITISMPREZENTIM-16 (2).png"
import endriti3 from "../Assets/ENDRITISMPREZENTIM-18 (1).png"
import endriti4 from "../Assets/ENDRITISMPREZENTIM-10 (1).png"
import endriti5 from "../Assets/ENDRITISMPREZENTIM-07 (1).png"
import endriti6 from "../Assets/ENDRITISMPREZENTIM-11 (1).png"
import endriti7 from "../Assets/ENDRITISMPREZENTIM-01 (1).png"
import endriti8 from "../Assets/ENDRITISMPREZENTIM-02 (1).png"

import gjelber from "../Assets/gjelber-06 (1).png"
import gjelber2 from "../Assets/gjelber-13 (1).png"
import gjelber3 from "../Assets/gjelber-05 (1).png"
import gjelber4 from "../Assets/gjelber-14 (1).png"
import gjelber5 from "../Assets/gjelber-09 (1).png"
import gjelber6 from "../Assets/gjelber-12 (1).png"
import gjelber7 from "../Assets/gjelber-07 (1).png"
import gjelber8 from "../Assets/Logo komplet (1).mp4"
import gjelber9 from "../Assets/Post_2 (1).mp4"
import gjelber10 from "../Assets/gjelber-11 (1).png"
import gjelber11 from "../Assets/gjelber-03 (1).png"

import easybuild from "../Assets/izy buildQW-09 (1).png"
import easybuild1 from "../Assets/izy buildQW-10 (1).png"
import easybuild2 from "../Assets/izy buildQW-08 (1).png"
import easybuild3 from "../Assets/izy build (1).jpg"
import easybuild4 from "../Assets/izy buildQW-13 (1).png"
import easybuild5 from "../Assets/izy buildQW-12 (1).png"
import easybuild6 from "../Assets/izy buildQW-01 (1) (1).png"
import easybuild7 from "../Assets/izy buildQW-04 (1).png"
import easybuild8 from "../Assets/izy buildQW-02 (1) (1).png"

import kok from "../Assets/KOK-MAKING OFF.mp4"
import kokFinal from "../Assets/kok-final.mp4"

import besiana from "../Assets/BESIANA.png"
import besiana1 from "../Assets/besianaSpecKuq74 (1).png"
import besiana2 from "../Assets/biberi.mp4"
import besiana3 from "../Assets/besiana3.png"
import besiana4 from "../Assets/besiana4.png"
import besiana5 from "../Assets/pire74 (1).png"
import besiana6 from "../Assets/sodaBikarboni74 (1).png"
import besiana7 from "../Assets/boloneze74 (1).png"
import besiana8 from "../Assets/bonafide1Photo (1).png"
import besiana9 from "../Assets/bonafide2Photo (1).png"
import besiana10 from "../Assets/perimesh74 (1).png"
import besiana11 from "../Assets/gullash74.png"

import master1 from "../Assets/Maestro Brand SM POST-01 (1) (1).png"
import master2 from "../Assets/Maestro Brand SM POST-11 (2).png"
import master3 from "../Assets/Maestro Brand SM POST-02 (1).png"
import master4 from "../Assets/Maestro Brand SM POST-14 (1).png"
import master5 from "../Assets/Maestro Brand SM POST-15 (1).png"
import master6 from "../Assets/Zemra masterfood_1_2 (1).mp4"
import master7 from "../Assets/Maestro Brand SM POST-03 (1).png"
import master8 from "../Assets/Maestro Brand SM POST-04 (1).png"
import master9 from "../Assets/Maestro Brand SM POST-08 (1).png"
import master10 from "../Assets/Maestro Brand SM POST-09 (1).png"
import master11 from "../Assets/Maestro Brand SM POST-16 (1).png"
import master12 from "../Assets/Maestro Brand SM POST-05 (1).png"
import master13 from "../Assets/Maestro Brand SM POST (1) (1).jpg"
import master14 from "../Assets/Maestro Brand SM POST-13 (1).png"

import astravel from "../Assets/square_Zanzi (1) (1).mp4"
import astravel1 from "../Assets/Prezantimi AS Travel 15 vite SM-02 (1).jpg"
import astravel2 from "../Assets/square_Mauritius (1) (1).mp4"
import astravel3 from "../Assets/Prezantimi AS Travel 15 vite SM-07 (1).jpg"
import astravel5 from "../Assets/Prezantimi AS Travel 15 vite SM-06 (1).jpg"
import astravel4 from "../Assets/square_Srilanka (1) (1).mp4"
import astravel6 from "../Assets/Prezantimi AS Travel 15 vite SM-12 (1).jpg"
import astravel7 from "../Assets/Prezantimi AS Travel 15 vite SM-11 (1).jpg"
import astravel8 from "../Assets/Prezantimi AS Travel 15 vite SM-10 (1).jpg"
import astravel9 from "../Assets/Prezantimi AS Travel 15 vite SM-09 (1).jpg"
import astravel10 from "../Assets/Prezantimi AS Travel 15 vite SM-05 (1).jpg"

const ourWorks = [
  { 
    id:14,
    workImage: kok,
    firstSinglePhoto: kokFinal,
    workName: "Olympic Commite of Kosovo",
    category:"Brand Strategy",
    workDescription: "Logo / Branding / Marketing Strategy / PR / Animation & 3D / Web Design & Development", 
    textDescription: "",
    hoverText: "Campaign / Design / Logo"
  },
      // Work 1
    { 
      id:2,
      workImage: work1,
      firstSinglePhoto: vegza1,
      secondSinglePhoto: vegza2,
      thirdSinglePhoto: vegza3,
      fourthSinglePhoto: vegza4,
      fifthSinglePhoto: vegza5,
      workName: "VEGZA",
      category:"Brand Strategy",
      workDescription: "Logo / Branding / Marketing Strategy / PR / Animation & 3D / Web Design & Development", 
      textDescription: "Embracing the fusion of asymmetry and modernism, Vegza's newest logo redesign beautifully intertwines the timeless essence of heritage with the contemporary edge of innovation. With its dynamic asymmetrical lines, each stroke tells a unique story, reflecting the complexity and depth of their architectural vision.",
      hoverText: "Campaign / Design / Logo"
    },
      // Work 2
      {
        id:12,
        workImage: easybuild,
        firstSinglePhoto: easybuild1,
        secondSinglePhoto: easybuild2,
        thirdSinglePhoto: easybuild3,
        fourthSinglePhoto: easybuild,
        fifthSinglePhoto: easybuild4,
        sixthSinglePhoto: easybuild5,
        seventhSinglePhoto: easybuild6,
        eightSinglePhoto: easybuild7,
        ninthSinglePhoto: easybuild8,
        workName: "EASY BUILD",
        category:"Brand Strategy",
        workDescription: "Design Brand Strategy / Web Design / Web Development"
      },
      { 
        id:20,
        workImage: astravel,
        firstSinglePhoto: astravel1,
        secondSinglePhoto: astravel2,
        thirdSinglePhoto: astravel3,
        fourthSinglePhoto: astravel5,
        fifthSinglePhoto: astravel4,
        sixthSinglePhoto: astravel,
        seventhSinglePhoto: astravel6,
        eightSinglePhoto: astravel7,
        ninthSinglePhoto: astravel8,
        tenthSinglePhoto: astravel9,
        eleventhSinglePhoto: astravel10,
        workName: "AS Travel",
        category:"Brand Strategy",
        workDescription: "Logo / Branding / Packaging Design / Marketing Strategy" ,
        textDescription: "We take great pride in partaking in one of the most monumental transformations within the public sphere, marking it as a beacon of success and innovation since the year 2006. In the mission of reshaping TERMOKOS, Trekuartista ingeniously infused the distinctive silhouette of its buildings into the design, preserving the essence of its origin while infusing an artistic approach."
      },
    { 
      id:3,
      workImage: work2,
      firstSinglePhoto: termokosBanner,
      secondSinglePhoto: termokos2,
      thirdSinglePhoto: termokos3,
      fourthSinglePhoto: termokos6,
      fifthSinglePhoto: termokos4,
      sixthSinglePhoto: termokos7,
      seventhSinglePhoto: termokos5,
      workName: "Termokos",
      category:"Brand Strategy",
      workDescription: "Logo / Branding / Packaging Design / Marketing Strategy" ,
      textDescription: "We take great pride in partaking in one of the most monumental transformations within the public sphere, marking it as a beacon of success and innovation since the year 2006. In the mission of reshaping TERMOKOS, Trekuartista ingeniously infused the distinctive silhouette of its buildings into the design, preserving the essence of its origin while infusing an artistic approach."
    },
      // Work 3
    {
      id:4,
      workImage: mokne5,
      firstSinglePhoto: mokne5,
      secondSinglePhoto: mokne1,
      thirdSinglePhoto: mokne2,
      fourthSinglePhoto: mokne3,
      fifthSinglePhoto: mokne4,
      sixthSinglePhoto: mokne5,
      seventhSinglePhoto: mokne6,
      workName: "MOKNE",
      category:"Brand Strategy",
      workDescription: "Bottle / Mokne / Logo / Design Brand Strategy / Natyral Water from Istog"
    },
    { 
      id:1,
      workImage: mainBoyut,
      firstSinglePhoto: mainBoyut,
      secondSinglePhoto: boyut2,
      thirdSinglePhoto: boyut3,
      fourthSinglePhoto: boyut4,
      fifthSinglePhoto: boyut5,
      sixthSinglePhoto: boyut6,
      seventhSinglePhoto: boyut7,
      eightSinglePhoto: boyut8,
      ninthSinglePhoto: boyut9,
      tenthSinglePhoto: boyut12,
      eleventhSinglePhoto: boyut10,
      twelfthSinglePhoto: boyut11,
      thirteenSinglePhoto: boyut13,
      fourteenthSinglePhoto: boyut16,
      fifteenthSinglePhoto: boyut15,
      sixteenthSinglePhoto: boyut14,
      seventeenthSinglePhoto: boyut17,
      workName: "BOYUT",
      category:"Brand Strategy",
      workDescription: "Logo / Branding / Marketing Strategy / PR / Animation & 3D", 
      textDescription: "We are incredibly proud to have played a key role in Boyut Plastik's transformative journey. Our creative approach to the new logo design blends the brand's unique features with an artistic flair, preserving its core identity while embracing innovation. Boyut Plastik continues to lead in plastics and sustainable solutions, dedicated to ongoing improvement and excellence.",
      hoverText: "Campaign / Design / Logo"
    },
    {
      id:11,
      workImage: gjelber,
      firstSinglePhoto: gjelber2,
      secondSinglePhoto: gjelber9,
      thirdSinglePhoto: gjelber8,
      fourthSinglePhoto: gjelber4,
      fifthSinglePhoto: gjelber5,
      sixthSinglePhoto: gjelber6,
      seventhSinglePhoto: gjelber7,
      eightSinglePhoto: gjelber3,
      ninthSinglePhoto: gjelber,
      tenthSinglePhoto: gjelber10,
      eleventhSinglePhoto: gjelber11,
      workName: "GJELBËR",
      category:"Brand Strategy",
      workDescription: "Design Brand Strategy / Web Design / Web Development"
    },
    
    {
      id:5,
      workImage: dokutech1,
      firstSinglePhoto: dokutech2,
      secondSinglePhoto: dokutech3,
      thirdSinglePhoto: dokutech4,
      fourthSinglePhoto: dokutech5,
      fifthSinglePhoto: dokutech6,
      sixthSinglePhoto: dokutech7,
      seventhSinglePhoto: dokutech8,
      eightSinglePhoto: dokutech9,
      ninthSinglePhoto: dokutech10,
      workName: "DOKUTECH - 2023",
      category:"Brand Strategy",
      workDescription: "Design Brand Strategy / PR / Animation / 3D Modeling"
    },
    {
      id:6,
      workImage: dokutech2022,
      firstSinglePhoto: dokutech2022,
      secondSinglePhoto: dokutech20221,
      thirdSinglePhoto: dokutech20222,
      fourthSinglePhoto: dokutech20223,
      fifthSinglePhoto: dokutech20224,
      sixthSinglePhoto: dokutech20225,
      seventhSinglePhoto: dokutech20226,
      eightSinglePhoto: dokutech20227,
      ninthSinglePhoto: dokutech20228,
      tenthSinglePhoto: dokutech20229,
      eleventhSinglePhoto: dokutech202210,
      twelfthSinglePhoto: dokutech202211,
      thirteenSinglePhoto: dokutech202212,
      workName: "DOKUTECH - 2022",
      category:"Brand Strategy",
      workDescription: "Design Brand Strategy / PR / Animation / 3D Modeling"
    },
    {
      id:16,
      workImage: master1,
      firstSinglePhoto: master2,
      secondSinglePhoto: master6,
      thirdSinglePhoto: master3,
      fourthSinglePhoto: master7,
      fifthSinglePhoto: master9,
      sixthSinglePhoto: master10,
      seventhSinglePhoto: master8,
      eightSinglePhoto: master4,
      ninthSinglePhoto: master5,
      tenthSinglePhoto: master11,
      eleventhSinglePhoto: master12,
      twelfthSinglePhoto: master13,
      thirteenSinglePhoto: master14,
      workName: "MASTER FOOD",
      category:"Brand Strategy",
      workDescription: "Bottle / Logo / Design Brand Strategy"
    },
    {
      id:7,
      workImage: endritiPremium,
      firstSinglePhoto: endriti1,
      secondSinglePhoto: endritiPremium,
      thirdSinglePhoto: endriti2,
      fourthSinglePhoto: endriti3,
      fifthSinglePhoto: endriti4,
      sixthSinglePhoto: endriti5,
      seventhSinglePhoto: endriti6,
      eightSinglePhoto: endriti7,
      ninthSinglePhoto: endriti8,
      workName: "ENDRITI PREMIUM CARS",
      category:"Brand Strategy",
      workDescription: "Bottle / Logo / Design Brand Strategy"
    },
    {
      id:8,
      workImage: drops1,
      firstSinglePhoto: drops3,
      secondSinglePhoto: drops1,
      thirdSinglePhoto: drops7,
      fourthSinglePhoto: drops,
      fifthSinglePhoto: drops4,
      sixthSinglePhoto: drops2,
      seventhSinglePhoto: drops8,
      eightSinglePhoto: drops5,
      ninthSinglePhoto: drops6,
      workName: "DROPS",
      category:"Brand Strategy",
      workDescription: "Bottle / Logo / Design Brand Strategy"
    },
    {
      id:9,
      workImage: binni1,
      firstSinglePhoto: binni2,
      secondSinglePhoto: binni1,
      thirdSinglePhoto: binni3,
      fourthSinglePhoto: binni4,
      fifthSinglePhoto: binni5,
      sixthSinglePhoto: binni6,
      seventhSinglePhoto: binni7,
      workName: "BINNI",
      category:"Brand Strategy",
      workDescription: "Bottle / Logo / Design Brand Strategy"
    },
    {
      id:15,
      workImage: besiana,
      firstSinglePhoto: besiana1,
      secondSinglePhoto: besiana2,
      thirdSinglePhoto: besiana3,
      fourthSinglePhoto: besiana4,
      fifthSinglePhoto: besiana5,
      sixthSinglePhoto: besiana6,
      seventhSinglePhoto: besiana7,
      eightSinglePhoto: besiana8,
      ninthSinglePhoto: besiana9,
      tenthSinglePhoto: besiana10,
      eleventhSinglePhoto: besiana11,
      workName: "ANANAS IMPEX",
      category:"Brand Strategy",
      workDescription: "Bottle / Logo / Design Brand Strategy"
    },
    {
      id:10,
      workImage: frutaria,
      firstSinglePhoto: frutaria1,
      secondSinglePhoto: frutaria2,
      thirdSinglePhoto: frutaria3,
      fourthSinglePhoto: frutaria4,
      fifthSinglePhoto: frutaria5,
      sixthSinglePhoto: frutaria6,
      seventhSinglePhoto: frutaria7,
      workName: "FRUTARIA",
      category:"Brand Strategy",
      workDescription: "Bottle / Logo / Design Brand Strategy"
    },
   
]

export { ourWorks }